/**
 * Contains the validation for the surveys in survey service
 */

define(['$window', 'app', 'simpleSlider'], function($window, app, simpleSlider) {

  class surveyServiceValidation {

    constructor() {
      this.isMobile = $window.outerWidth < 500;
      this.init(this.isMobile);
    }

    init(isMobile) {
      this.options = {
        wrapper: '.survey_sliderWrapper',
        slide: '.survey_slideItem',
        loop: false,
        switchKey: false,
        hasSwipe: false
      };

      this.slider = new simpleSlider('.surveySimpleSlider', this.options);
      this.getFirstSlide();
      this.nextHandler();
      this.submitHandler();
      this.prevHandler();
      this.errorRemoveHandler();
      this.keyHandler();
      this.sliderHeight();

      if (isMobile) {
        this.fixedNavButtons();
      }
    }

    getFirstSlide() {
      this.currentSlide = this.slider.currentSlide.children[0];
      this.currentSlideNumber = this.currentSlide.getAttribute('data-slidenumber');

      if (this.currentSlideNumber === '0') {
        this.hidePreviousButton();
      }
    }

    hidePreviousButton() {
      this.previousButton = document.querySelector('.survey_formQuestions-prevButton');
      this.previousButton.classList.add('survey_prevButton-hide');
    }

    nextHandler() {
      const SELF = this;
      SELF.nextButton = document.querySelector('.survey_formQuestions-nextButton');
      SELF.nextButton.addEventListener('click', function(e) {
        e.preventDefault();
        SELF.validation(SELF.slider.current + 1);
        SELF.getLastSlide();
      });
    }

    submitHandler() {
      const SELF = this;
      SELF.submitButton = document.querySelector('.survey_formQuestions-submitButton');
      SELF.submitButton.addEventListener('click', function(e) {
        e.preventDefault();

        if (SELF.validation(SELF.slider.current)) {
          document.getElementById('survey').submit();
        }
      });
    }

    validation(index) {
      const type = this.slider.currentSlide.getAttribute('data-type');

      switch (type) {
        case 'free-text':
          const textareas = this.slider.currentSlide.querySelectorAll('textarea');
          return this.validityText(textareas, index);

        case 'multi-select':
          const m_inputs = this.slider.currentSlide.querySelectorAll('input');
          const maxQuestions = this.slider.currentSlide.querySelector('[data-maxquestions]');
          const minQuestions = this.slider.currentSlide.querySelector('[data-minquestions]');

          if (maxQuestions || minQuestions) {
            const maxQu = maxQuestions.getAttribute('data-maxquestions') || 1;
            const minQu = minQuestions.getAttribute('data-minquestions') || 1;

            return this.checkMinMax(maxQu, minQu, m_inputs, index);
          }

          // else
          return this.validityInput(m_inputs, index);

        case 'single-select':
          const s_inputs = this.slider.currentSlide.querySelectorAll('input');
          return this.validityInput(s_inputs, index);

        case 'likert-scale':
          const subQuestions = this.slider.currentSlide.querySelectorAll('.survey_container-likert');
          return this.validateLikert(subQuestions, index);

        default:
          this.showError();
          return false;
      }
    }

    checkMinMax(max, min, inputs, index) {
      let count = 0;
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
          count++;
        }
      }
      if (count >= min && count <= max) {
        this.validityInput(inputs, index);
        return true;
      } else {
        this.showMinMaxError();
        return false;
      }
    }

    validateLikert(radios, index) {
      let count = 0;
      for (let i = 0; i < radios.length; i++) {
        let radioInputs = radios[i].querySelectorAll('input');
        for (let j = 0; j < radioInputs.length; j++) {
          if (radioInputs[j].checked) {
            count++;
          }
        }
      }

      if (radios.length === count) {
        this.moveSlide(index);
        this.showPreviousButton();
        return true;
      } else {
        this.showError();
        return false;
      }
    }

    validityInput(inputs, index) {
      let valid = false;
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
          valid = true;
        }
      }
      if (valid) {
        this.moveSlide(index);
        this.showPreviousButton();
        return true;
      } else {
        this.showError();
        return false;
      }
    }

    validityText(inputs, index) {
      let valid = false;
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value) {
          valid = true;
        }
      }
      if (valid) {
        this.moveSlide(index);
        this.showPreviousButton();
        return true;
      } else {
        this.showError();
        return false;
      }
    }

    moveSlide(index) {
      this.slider.moveSlide(index);
      this.sliderHeight();
    }

    sliderHeight() {
      this.currentSlideHeight = this.slider.currentSlide.clientHeight;
      this.slider.wrapper.style.height = `${this.currentSlideHeight + 50}px`;
      this.backToTop();
    }

    backToTop() {
      app.element.scrollTo(1, 400);
    }

    showPreviousButton() {
      this.previousButton = document.querySelector('.survey_formQuestions-prevButton');
      if (this.previousButton) {
        this.previousButton.classList.remove('survey_prevButton-hide');
      }
    }

    errorRemoveHandler() {
      const SELF = this;
      this.slider.wrapper.addEventListener('click', function() {
        SELF.removeError();
      });
    }

    showError() {
      this.backToTop();
      this.error = this.slider.currentSlide.querySelector('.survey_errorMessage');
      if (this.error) {
        this.error.classList.remove('survey_errorMessage-hide');
      }
    }

    showMinMaxError() {
      this.backToTop();
      this.errorMinMax = this.slider.currentSlide.querySelector('.survey_errorMessage_minMax');
      if (this.errorMinMax) {
        this.errorMinMax.classList.remove('survey_errorMessage-hide');
      }
    }

    removeError() {
      this.error = this.slider.currentSlide.querySelector('.survey_errorMessage');
      if (this.error) {
        this.error.classList.add('survey_errorMessage-hide');
      }
      this.errorMinMax = this.slider.currentSlide.querySelector('.survey_errorMessage_minMax');
      if (this.errorMinMax) {
        this.errorMinMax.classList.add('survey_errorMessage-hide');
      }
    }

    showSubmit() {
      this.nextBtn = document.querySelector('.survey_formQuestions-nextButton');
      this.submitBtn = document.querySelector('.survey_formQuestions-submitButton');

      this.nextBtn.classList.add('survey_nextButton-hide');
      this.submitBtn.classList.remove('survey_submitButton-hide');
    }

    hideSubmit() {
      this.nextBtn = document.querySelector('.survey_formQuestions-nextButton');
      this.submitBtn = document.querySelector('.survey_formQuestions-submitButton');

      this.nextBtn.classList.remove('survey_nextButton-hide');
      this.submitBtn.classList.add('survey_submitButton-hide');
    }

    getLastSlide() {
      const SELF = this;

      SELF.totalSlideNumber = SELF.slider.slides.length;
      SELF.currentSlideChild = SELF.slider.currentSlide.children[0];
      SELF.currentSlideNumber = app.element.getAttribute('data-slidenumber', SELF.currentSlideChild);

      SELF.lastSlideNumber = SELF.totalSlideNumber - 1;
      SELF.lastSlideNumberInt = SELF.lastSlideNumber.toString();

      if (SELF.currentSlideNumber === SELF.lastSlideNumberInt) {
        SELF.showSubmit();
      } else {
        SELF.hideSubmit();
      }
    }

    prevHandler() {
      const SELF = this;
      SELF.prev = document.querySelector('.survey_formQuestions-prevButton');
      SELF.prev.addEventListener('click', function(e) {
        e.preventDefault();
        SELF.moveSlide(SELF.slider.current - 1);
        SELF.hideSubmit();
        SELF.getFirstSlide();
      });
    }

    keyHandler() {
      const SELF = this;

      $window.addEventListener('keydown', (e) => {
        SELF.checkKeyPress(e);
      });
    }

    checkKeyPress(e) {
      const SELF = this;

      if (e.keyCode === 37) {
        SELF.moveSlide(SELF.slider.current - 1);
        SELF.hideSubmit();
        SELF.getFirstSlide();
      }

      if (e.keyCode === 39) {
        SELF.validation(SELF.slider.current + 1);
        SELF.getLastSlide();
      }
    }

    fixedNavButtons() {
      let navButtons = document.querySelector('.survey_formQuestionsButtonWrapper');
      $window.addEventListener('scroll', () => {
        let isRelative = ($window.scrollY + $window.innerHeight) <= this.topFooter;
        this.toggleNavButtons(navButtons, isRelative);
      });
    }

    toggleNavButtons(navButtons, isRelative) {
      if (isRelative) {
        app.element.removeClass('survey_formQuestionsButtonWrapper-relative', navButtons);
      } else {
        app.element.addClass('survey_formQuestionsButtonWrapper-relative', navButtons);
      }
    }
  }

  if (document.querySelector('.surveyComponent')) {
    return new surveyServiceValidation();
  } else {
    return surveyServiceValidation;
  } //for unit test

});
